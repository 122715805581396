import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';





const FreeTrialPopup = () => {
    const [show, setShow] = useState(false);

    // Close the modal
    const handleClose = () => setShow(false);

    // Automatically show the modal after 10 seconds (10000 ms)
    useEffect(() => {
        const timer = setTimeout(() => {
            setShow(true);
        }, 15000); // 10 seconds
        // Clear the timer if the component unmounts
        return () => clearTimeout(timer);
    }, []);

    return (
        <>
            {/* Modal */}
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Free Forex Trial Class</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    Enroll to our Two Month Program by taking advantage of our FREE TRIALS.
                    <br /><br />
                    {/* <h5>Upcoming Class Details:</h5> */}
                    {/* <p>
                        <strong>Date:</strong> 30th October <br />
                        <strong>Time:</strong> 10:00 am
                    </p> */}
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button
                        style={{ backgroundColor: '#0b1632', borderColor: '#0b1632' }}
                        variant="success"
                        href="/trialclass"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Enroll Now
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};


export default FreeTrialPopup;


